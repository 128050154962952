<template>
  <div>
    <div><i class="fas fa-calendar-alt"></i> {{ field.Value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
};
</script>
